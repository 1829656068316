import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import SectionHeader from '../components/section-header'
import ContentBlock from '../components/content-block'
import Row from '../components/row'
import styles from '../components/about.module.css'

class AboutPage extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const missionObj = get(this, 'props.data.allContentfulContentBlock.edges[0]')
    const missionData = missionObj.node
    const principlesData = {
      sectionHeaderTitle: 'Our Principles',
      sectionHeaderContent: 'Drug addiction is a chronic disease characterized by brain changes that occur over time with drug use which challenge an addicted person’s self-control and interfere with their ability to resist intense urges to take drugs. Most drugs affect the brain\'s reward circuit by flooding it with the chemical messenger dopamine. Surges of dopamine in the reward circuit cause the reinforcement of pleasurable but unhealthy activities, leading people to repeat the behavior again and again.',
    }
    const teamData = {
      sectionHeaderTitle: 'Our Team',
      sectionHeaderContent: 'Drug addiction is a chronic disease characterized by brain changes that occur over time with drug use which challenge an addicted person’s self-control and interfere with their ability to resist intense urges to take drugs. Most drugs affect the brain\'s reward circuit by flooding it with the chemical messenger dopamine. Surges of dopamine in the reward circuit cause the reinforcement of pleasurable but unhealthy activities, leading people to repeat the behavior again and again.',
    }

    const headerData = {
      title: 'About Us'
    }

    return (
      <Layout location={this.props.location}>
          <Helmet title={siteTitle} />
          <SectionHeader data={headerData} />
          <Row>
            <div className={styles.missionContainer}>
              <h2>Our Mission</h2>
              <p>We work to prevent high-risk substance use, provide support for addiction recovery, and reduce the social stigma of addiction and recovery, in Ben’s honor.</p>
              <p>More specifically, we have 3 primary focus areas:</p>
              <ol className='missionList'>
                <li>Provide financial support to those who can’t afford the continuum of care required to increase their chances of successful recovery from addiction.</li>
                <li>Raise awareness about the dangers of an increasingly contaminated drug supply.</li>
                <li>Reduce the social stigma surrounding frank discussion of addiction and substance abuse.</li>
              </ol>
            </div>
          </Row>
          <hr />
          <Row>
            <div className={styles.missionContainer}>
              <h2>Our Principles</h2>
              <ol className='principlesList'>
                <li>Honor Ben, his life, his spirit, his passion, his desire to help other people.</li>
                <li>Serve others by doing meaningful and impactful work in the area of substance abuse.</li>
                <li>Reflect what Ben would have wanted in our organization, our goals, our work and our interactions.</li>
                <li>Convey the extent of the need, how our work helps meet that need, and therefore why donations are meaningful.</li>
              </ol>
            </div>
          </Row>
          <hr />
          <Row>
          <div className={styles.missionContainer}>
            <h2>Our Team</h2>
            <ol className={styles.teamList}>
              <li>
                <p>Shauna and Mark Trieb</p>
                <p className={styles.team}>Co-chairs</p>
              </li>
              <li>
                <p>Jake Meyer</p>
                <p className={styles.team}>Board Member</p>
              </li>
              <li>
                <p>Nash Harloe</p>
                <p className={styles.team}>Board Member</p>
              </li>

              <li>
                <p>Anji and Zeke Cisco</p>
                <p className={styles.team}>Marketing</p>
              </li>
              <li>
                <p>Erin O'Keefe</p>
                <p className={styles.team}>Fundraising</p>
              </li>



              <li>
                <p>Christian De Oliveira</p>
                <p className={styles.team}>Recovery</p>
              </li>
              <li>
                <p>Dr. Adam Silberstein, PsyD</p>
                <p className={styles.team}>Recovery</p>
              </li>
              <li>
                <p>Emily Terwelp</p>
                <p className={styles.team}>Education</p>
              </li>
              <li>
                <p>Tyler Terwelp</p>
                <p className={styles.team}>Education</p>
              </li>
              <li>
                <p>Erin Trieb</p>
                <p className={styles.team}>Creative</p>
              </li>
              <li>
                <p>Michael Wells</p>
                <p className={styles.team}>Fundraising</p>
              </li>
            </ol>
          </div>
          </Row>
      </Layout>
    )
  }
}

export default AboutPage

export const pageQuery = graphql`
  query MyQuery {
    allContentfulContentBlock(filter: {page: {eq: "about"}}) {
      edges {
        node {
          id
          backgroundImage {
            file {
              url
            }
          }
          contentText {
            contentText
          }
          contentful_id
          link
          linkText
          title
          imagePosition
        }
      }
    }
  }

`
